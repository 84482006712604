import { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter,
  Switch,
  useParams,
  Route,
  Link,
} from "react-router-dom";

import recipeData from "./data.json";

import { sortVolumes } from "./Util";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <RecipeList />
          </Route>
          <Route path="/recipe/:id" children={<Recipe />}></Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

function RecipeList() {
  const [recipes, setRecipes] = useState([]);
  const [filter, setFilter] = useState("");

  const fetchRecipes = () => {
    var filteredRecipes = recipeData.filter(
      (item) =>
        item.type == "default" &&
        item.name.toLowerCase().includes(filter.toLowerCase())
    );
    setRecipes(filteredRecipes);
  };

  useEffect(() => {
    fetchRecipes();
  }, [filter]);

  return (
    <div class="recipeList">
      <div class="filterAreaWrapper">
        <div class="filterArea">
          <input
            id="nameFilter"
            placeholder="Suche..."
            onChange={(e) => setFilter(e.target.value)}
          ></input>
        </div>
      </div>
      {recipes.map((recipe, i) => {
        return (
          <Link to={"/recipe/" + `${recipe._id}`}>
            <div className="recipeTileWrapper">
              <div
                className="recipeTile"
                key={recipe._id}
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(255,255,255,0), rgba(0,0,0,0.8)), url(" +
                    `${recipe.image_urls.low}` +
                    ")",
                }}
              >
                <span className="name">{recipe.name}</span>
                <br />
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}

function Recipe() {
  let { id } = useParams();

  const [recipe, setRecipe] = useState({});

  const fetchRecipe = (fetchId) => {
    let data = recipeData.filter((item) => item._id == fetchId)[0];
    data.volumes = sortVolumes(data.volumes);
    setRecipe(data);
  };

  useEffect(() => {
    fetchRecipe(id);
  }, [id]);

  const renderNumberColumn = (number, unit, decimals = undefined) => {
    if (typeof number == "number" && decimals != undefined) {
      number = number.toFixed(decimals);
    }
    let firstPart = Math.trunc(number);
    let secondPart = number - Math.trunc(number);
    if (secondPart == 0.5) {
      if (firstPart == 0) {
        firstPart = "";
      }
      secondPart = "½";
    } else if (secondPart == 0.25) {
      if (firstPart == 0) {
        firstPart = "";
      }
      secondPart = "¼";
    } else if (secondPart != 0) {
      secondPart = (secondPart + "").substring(1);
    } else {
      secondPart = "";
    }

    let thirdPart = unit;

    if (isNaN(firstPart)) {
      firstPart = "";
    }

    return (
      <>
        <td className="wholeNumber">{firstPart}</td>
        <td className="fractionNumber">{secondPart}</td>
        <td className="unit">{thirdPart}</td>
      </>
    );
  };

  return (
    <div class="recipe">
      <Link to="/">
        <div className="backNavigation">&lt; Zurück</div>
      </Link>
      {recipe && Object.keys(recipe).length !== 0 && (
        <>
          <img class="recipeImage" src={recipe.image_urls.medium} />

          <div className="recipeName">{recipe.name}</div>
          <div className="shortDescription">{recipe.short_description}</div>
          <div className="nutVal">
            <h2>Nährstoffe</h2>
            <table>
              <colgroup>
                <col className="nutritionName"></col>
                {sortVolumes(recipe.volumes).map((volume, i) => {
                  return <col className="amount"></col>;
                })}
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  {sortVolumes(recipe.volumes).map((volume, i) => {
                    return (
                      <th className="amount" colspan="3">
                        {volume}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Kohlenhydrate</th>
                  {sortVolumes(recipe.volumes).map((volume, i) => {
                    return renderNumberColumn(
                      recipe.carbohydrate_g[volume],
                      "",
                      0
                    );
                  })}
                </tr>
                <tr>
                  <th>Fett</th>
                  {recipe.volumes.map((volume, i) => {
                    return renderNumberColumn(recipe.fat_g[volume], "", 0);
                  })}
                </tr>
                <tr>
                  <th>Eiweiß</th>
                  {recipe.volumes.map((volume, i) => {
                    return renderNumberColumn(recipe.protein_g[volume], "", 0);
                  })}
                </tr>
                <tr>
                  <th>kcal</th>
                  {recipe.volumes.map((volume, i) => {
                    return renderNumberColumn(recipe.kcal[volume], "", 0);
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div class="ingredients">
            <h2>Zutaten</h2>
            <table>
              <colgroup>
                <col className="nutritionName"></col>
                {sortVolumes(recipe.volumes).map((volume, i) => {
                  return <col className="amount"></col>;
                })}
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  {recipe.volumes.map((volume, i) => {
                    return <th colspan="3">{volume}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {recipe.ingredients.map((ingredient, i) => {
                  return (
                    <>
                      <tr key={ingredient.id}>
                        <th className="ingredientName">{ingredient.name}</th>
                        {recipe.volumes.map((volume, i) => {
                          return renderNumberColumn(
                            ingredient.amounts[volume],
                            ingredient.unit
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="steps">
            <h2>Zubereitung</h2>
            <ul>
              {recipe.steps.map((step, i) => {
                return <li>{step}</li>;
              })}
            </ul>
          </div>
          {recipe.hasOwnProperty("seasonings") && recipe.seasonings.length > 0 && (
            <div className="seasonings">
              <h2>Gewürze</h2>
              <ul>
                {recipe.seasonings.map((seasoning, i) => {
                  return <li>{seasoning}</li>;
                })}
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
export { RecipeList, Recipe };
